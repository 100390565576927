



















import {Component, Mixins, Prop} from 'vue-property-decorator';
import BecomeAModel from './BecomeAModel.vue';
import NumbersMixin from '@/mixins/NumbersMixin';
import TopModelsMixin from '@/mixins/TopModelsMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';

@Component({
  components: {BecomeAModel},
})
export default class TopModels extends Mixins(NumbersMixin, TopModelsMixin, DefaultAvatarMixin) {
  @Prop({default: true}) readonly isMobile!: boolean;
}
