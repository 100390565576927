


































import {Component, Mixins, Watch} from 'vue-property-decorator';
import TopModels from '@/components/TopModels.vue';
import DropDown from '@/components/DropDown.vue';
import {vxm} from '@/store';
import {VideosSearchParamsInterface} from '@/types/VideosSearchParamsInterface';
import {VideoInterface} from '@/types/videoInterface';
import DebounceMixin from '@/mixins/DebounceMixin';
import Loader from '@/components/Loader.vue';
import PostCard from '@/components/PostCard.vue';
import moment from 'moment';
import {ModelDataInterface} from '@/types/userDataInterface';

import FeedsSection from '@/components/sections/FeedsSection.vue';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import VideoPlayer from '@/components/VideoPlayer.vue';
import PostCardNew from '@/components/PostCardNew.vue';

@Component({
  components: {Loader, TopModels, PostCard, DropDown, FeedsSection, VideoPlayer, PostCardNew},
})
export default class VidsNew extends Mixins(DebounceMixin, MomentDateMixin) {
  vids: VideoInterface[] = [];
  isMobile = false;
  viewType = 'grid';
  sort = {
    newest: this.$t('recentlyAdded'),
    oldest: this.$t('oldest'),
    mostView: this.$t('mostPopular'),
    mostLike: this.$t('most-like'),
  };
  params: VideosSearchParamsInterface = {
    page: 1,
    take: 20,
    isSaleVideo: true,
    isPurchased: true,
    // isSubscribed: true,
    sort: 'newest',
    keyword: '',
  };
  isRequestSend = false;
  totalVids = 0;
  query = '';
  debouncedSearch = this.debounce(this.search, 700);
  processing = false;
  randomPromoIndex = this.randomInt(3, 8);

  @Watch('query') onQueryChange() {
    this.debouncedSearch();
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  mounted() {
    this.getVids();
    // window.addEventListener('scroll', this.handleScroll);
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
    // window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    // const bottomOfWindow = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (!this.isRequestSend && this.totalVids > this.vids.length) {
      this.getVids();
    }
  }

  refreshVids() {
    this.params.page = 1;
    this.vids = [];
    this.getVids();
  }

  search() {
    this.params.keyword = this.query;
    this.refreshVids();
  }

  // get random index to create blank space in grid
  randomInt(min: number, max: number) {
    return min + Math.floor((max - min) * Math.random());
  }
  getVids() {
    if (this.params.keyword === '') {
      this.params.keyword = undefined;
    }

    this.isRequestSend = true;

    vxm.fan
      .getVideosSearch(this.params)
      .then((res) => {
        this.totalVids = res.data.count;
        this.vids.push(...res.data.items);
        (this.params.page as number)++;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  switchView(viewType: string) {
    this.viewType = viewType;
  }

  onResize() {
    this.isMobile = window.innerWidth <= 1023;
  }

  toModelPage(username: string) {
    if (this.$route.name !== 'model-vids') {
      this.$router.push(`/${username}`);
    }
    this.$emit('closed');
  }
}
