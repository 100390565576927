



































import {Component, Prop, Mixins} from 'vue-property-decorator';
import VideoPlayer from '@/components/VideoPlayer.vue';
import MomentDateMixin from '@/mixins/MomentDateMixin';
@Component({
  components: {
    VideoPlayer,
  }
})
export default class FeedsSection extends Mixins (
    MomentDateMixin
  ) {
  @Prop(Array) feeds!: [];
  @Prop(Number) limit_by!: number;
  @Prop(String) pageTitle!: string;
}
