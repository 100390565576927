import {Component, Vue} from 'vue-property-decorator';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import {PerformersSearchParamsInterface} from '@/types/PerformersSearchParamsInterface';

@Component
export default class TopModelsMixin extends Vue {
  topModels: ModelDataInterface[] = [];

  mounted() {
    if (!vxm.fan.leaderboard.length) {
      const params: PerformersSearchParamsInterface = {
        page: 1,
        take: 10,
        sort: 'popular',
        status: 'active',
      };

      vxm.fan
        .getLeaderboard(params)
        .then((res) => {
          this.topModels = res.data.items;
        })
        .catch((error) => {
          return error;
        });
    } else {
      this.topModels = vxm.fan.leaderboard;
    }
  }
}
